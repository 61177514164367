import { PurchaseOrder, ClosedCancelledState } from '../../../types/types';
import { GlobalContextAction } from '../globalReducer';
import { ClosedCancelledActionType } from './closedCancelledActions';

export const closedCancelledReducer = (
  state: ClosedCancelledState,
  action: GlobalContextAction
): ClosedCancelledState => {
  switch (action.type) {
    case ClosedCancelledActionType.SET_CLOSED_CANCELLED:
      return {
        ...state,
        data: action.payload as PurchaseOrder[],
      };
    case ClosedCancelledActionType.SET_SELECTED_CLOSED_CANCELLED_ID:
      return {
        ...state,
        selectedItemId: action.payload as string,
      };

    default:
      return {
        ...state,
      };
  }
};
