import React from 'react';
import { PreviouslyReceivedPOList } from './PreviouslyReceivedPOList';
import { RACAccordion, makeStyles, Typography } from '@rentacenter/racstrap';
import { SectionProps } from './ReceivingPO';
import { POSection } from '../../constants/constants';

const useStyles = makeStyles((theme: any) => ({
  receivingPOManualRoot: {
    margin: `${theme.typography.pxToRem(16)} 0`,
  },
  h4: {
    marginTop: '.5rem',
  },
  accordionSummaryContent: {
    margin: '0 !important',
  },
}));

export const PreviouslyReceivedPOSection = ({
  selected,
  onChange,
}: SectionProps) => {
  const classes = useStyles();
  const expanded = selected === POSection.PreviouslyReceivedPO;

  return (
    <>
      <RACAccordion
        details={<PreviouslyReceivedPOList expanded={expanded} />}
        summary={
          <Typography variant="h4" className={classes.h4}>
            Previously Received PO
          </Typography>
        }
        accordionProps={{
          classes: {
            root: classes.receivingPOManualRoot,
          },
          expanded: expanded,
          onChange: onChange(expanded ? '' : POSection.PreviouslyReceivedPO),
        }}
        summaryProps={{
          classes: {
            content: classes.accordionSummaryContent,
          },
        }}
      />
    </>
  );
};
