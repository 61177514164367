import { CancelToken } from 'axios';
import { appConfig } from '../config/app-config';
import {
  GetPurchaseOrderResponse,
  GetPurchaseOrderRequest,
  ReversePORequest,
  ReceivePORequest,
  StoreActionItemRequest,
} from '../types/types';
import { client } from './client';

export const getPurchaseOrders = (
  request: GetPurchaseOrderRequest,
  offset: number,
  limit: number,
  cancelToken?: CancelToken
): Promise<GetPurchaseOrderResponse> =>
  client(
    `purchase-orders?limit=${limit}&offset=${offset}`,
    {
      method: 'POST',
      cancelToken,
      body: request,
    },
    appConfig.apiUrls.manualpo,
    true
  );

export const receivePurchaseOrder = (
  request: ReceivePORequest,
  cancelToken?: CancelToken
) =>
  client(
    `receiving-po`,
    {
      method: 'POST',
      cancelToken,
      body: request,
    },
    appConfig.apiUrls.micro,
    true
  );
export const reversePurchaseOrder = (
  request: ReversePORequest,
  cancelToken?: CancelToken
) =>
  client(
    `reverse-po`,
    {
      method: 'POST',
      cancelToken,
      body: request,
    },
    appConfig.apiUrls.micro,
    true
  );
export const updateStoreActionItem = (
  request: StoreActionItemRequest,
  cancelToken?: CancelToken
) =>
  client(
    `inventory/storeConfig`,
    {
      method: 'POST',
      cancelToken,
      body: request,
    },
    appConfig.apiUrls.inventory,
    true
  );
