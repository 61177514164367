import { PurchaseOrder } from '../../../types/types';
import { Action } from '../../../types/types';

export enum ClosedCancelledActionType {
  SET_CLOSED_CANCELLED = 'SET_CLOSED_CANCELLED',
  SET_SELECTED_CLOSED_CANCELLED_ID = 'SET_SELECTED_CLOSED_CANCELLED_ID',
}

export interface ClosedCancelledAction extends Action {
  readonly type: any;
  readonly payload: any;
}

export const setClosedCancelled = (
  purchaseOrders: PurchaseOrder[]
): ClosedCancelledAction => ({
  type: ClosedCancelledActionType.SET_CLOSED_CANCELLED,
  payload: purchaseOrders,
});

export const setSelectedClosedCancelledId = (
  id: string
): ClosedCancelledAction => ({
  type: ClosedCancelledActionType.SET_SELECTED_CLOSED_CANCELLED_ID,
  payload: id,
});
