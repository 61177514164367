import {
  makeStyles,
  RACButton,
  RACCard,
  RACDateRangePicker,
  RACTextField,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { add, differenceInCalendarDays, format, sub } from 'date-fns';
import React, { useState, useEffect } from 'react';
import {
  useSearchActions,
  useSearchDetails,
} from '../../context/Search/SearchProvider';
import {
  POSearchFormLabel,
  REQUIRED_FIELD_MESSAGE,
  PO_NUMBER_INVALID_MESSAGE,
} from '../../constants/constants';
import { TWELVE_MONTHS_AGO, TWELVE_MONTHS_AFTER } from '../../utils/utils';

const dateFormat = 'yyyy-MM-dd';

const useStyles = makeStyles((theme: any) => ({
  searchRoot: {
    width: '100%',
    marginBottom: '1rem',
    display: 'block',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(32),
  },
  column: {
    display: 'flex',
    flex: 4,
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
  },
  buttonsColumn: {
    flex: 1,
    alignItems: 'flex-end',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardBody: {
    flex: '1 1 auto',
    padding: '2rem 2rem',
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: theme.typography.pxToRem(42),
    alignSelf: 'center',
    marginTop: theme.typography.pxToRem(19),
    gap: theme.typography.pxToRem(32),
  },
  field: {
    width: '12rem',
    '& input': {
      height: theme.typography.pxToRem(25),
    },
  },
  noPaddingLeft: {
    paddingLeft: '0 !important',
  },
  dateRangeLabel: {
    alignSelf: 'center',
    width: '9rem',
    marginLeft: theme.typography.pxToRem(37),
    fontWeight: 'bold',
    marginTop: theme.typography.pxToRem(17),
  },
}));

const PONumberSearch = new RegExp(/^\d{1,18}$|^M.{1,14}$/);

export const Search = () => {
  const classes = useStyles();
  const { manualPOReceive } = useSearchDetails();
  const { setSearchFilter, clearFilter, setManualPOReceive } =
    useSearchActions();

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [poNumber, setPONumber] = useState(manualPOReceive);
  const [toDateRangeError, setToDateRangeError] = useState('');
  const [fromDateRangeError, setFromDateRangeError] = useState('');
  const [minFrom, setMinFrom] = useState(format(TWELVE_MONTHS_AGO, dateFormat));
  const [maxFrom, setMaxFrom] = useState(
    format(TWELVE_MONTHS_AFTER, dateFormat)
  );
  const [minTo, setMinTo] = useState(format(TWELVE_MONTHS_AGO, dateFormat));
  const [maxTo, setMaxTo] = useState(format(TWELVE_MONTHS_AFTER, dateFormat));
  const [poNumberError, setPONumberError] = useState('');
  const isFormInValid = !fromDate && !toDate && !poNumber;

  const onClear = () => {
    setFromDate('');
    setToDate('');
    setMinFrom('');
    setMaxFrom('');
    setMinTo('');
    setMaxTo('');
    setPONumber('');
    setFromDateRangeError('');
    setToDateRangeError('');
    setManualPOReceive('');
    setPONumberError('');
    clearFilter();
  };

  const validateForm = () => {
    if (isFormInValid) return false;
    setPONumberError('');

    if (poNumber && !PONumberSearch.test(poNumber)) {
      setPONumberError(PO_NUMBER_INVALID_MESSAGE);
      return false;
    }

    if (fromDate && !toDate) {
      setToDateRangeError(REQUIRED_FIELD_MESSAGE);
      return false;
    }

    if (toDate && !fromDate) {
      setFromDateRangeError(REQUIRED_FIELD_MESSAGE);
      return false;
    }

    if (differenceInCalendarDays(new Date(fromDate), new Date(toDate)) > 31) {
      setToDateRangeError('Invalid range');
      setFromDateRangeError('Invalid range');
      return false;
    }
    return true;
  };

  const handleSearchClick = () => {
    if (!validateForm()) return;
    setManualPOReceive('');
    setSearchFilter(poNumber, fromDate, toDate);
  };

  useEffect(() => {
    if (manualPOReceive) {
      setSearchFilter(manualPOReceive, '', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(classes.searchRoot)}>
      <RACCard className={classes.card}>
        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.column)}>
            <div className={clsx(classes.row)}>
              <div className={clsx(classes.field)}>
                <RACTextField
                  label={POSearchFormLabel.PONumber}
                  type="text"
                  onChange={setPONumber}
                  value={poNumber}
                  name={POSearchFormLabel.PONumber}
                  errorMessage={poNumberError}
                />
              </div>
              <div className={clsx(classes.field, classes.dateRangeLabel)}>
                Search by Date Range
              </div>
              <div className={clsx(classes.field)}>
                <RACDateRangePicker
                  name="dateRange"
                  fromLabel="From:"
                  toLabel="To:"
                  fromValue={fromDate}
                  toValue={toDate}
                  minFrom={minFrom}
                  maxFrom={maxFrom}
                  minTo={minTo}
                  maxTo={maxTo}
                  onFromChanged={(value) => {
                    setFromDateRangeError('');
                    setToDateRangeError('');
                    setFromDate(value);
                    setMinTo(value);
                    setMaxTo(
                      format(add(new Date(value), { days: 30 }), dateFormat)
                    );
                  }}
                  onToChanged={(value) => {
                    setToDateRangeError('');
                    setFromDateRangeError('');
                    setToDate(value);
                    setMinFrom(
                      format(sub(new Date(value), { days: 30 }), dateFormat)
                    );
                    setMaxFrom(value);
                  }}
                  fromErrorMessage={fromDateRangeError}
                  toErrorMessage={toDateRangeError}
                />
              </div>
            </div>
          </div>
          <div className={clsx(classes.column, classes.buttonsColumn)}>
            <div className={classes.row}>
              <div className={classes.buttonContainer}>
                <RACButton
                  variant="text"
                  size="large"
                  color="primary"
                  onClick={onClear}
                >
                  Clear
                </RACButton>
                <RACButton
                  type="submit"
                  form="searchPOForm"
                  variant="contained"
                  size="small"
                  color="primary"
                  key="submitPO"
                  loading={false}
                  disabled={isFormInValid}
                  onClick={handleSearchClick}
                >
                  Search
                </RACButton>
              </div>
            </div>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
