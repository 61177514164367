import {
  ClosedCancelledState,
  PreviouslyReceivedState,
  SelectedPOToReceiveState,
} from '../../types/types';
import { PreviouslyReceivedAction } from './reducer/previouslyReceivedActions';
import { previouslyReceivedReducer } from './reducer/previouslyReceivedReducer';
import { selectedPOToReceiveReducer } from './reducer/selectedPOToReceiveReducer';
import { closedCancelledReducer } from './reducer/closedCancelledReducer';
export interface GlobalState {
  readonly previouslyReceived: PreviouslyReceivedState;
  readonly selectedPO: SelectedPOToReceiveState;
  readonly closedCancelled: ClosedCancelledState;
}

export type GlobalContextAction = PreviouslyReceivedAction;

export const initialState: GlobalState = {
  selectedPO: {} as SelectedPOToReceiveState,
  previouslyReceived: {
    data: [],
    selectedItemId: null,
    updatedPO: [],
  },
  closedCancelled: {
    data: [],
    selectedItemId: null,
  },
};

export const globalReducer = (
  state: GlobalState,
  action: GlobalContextAction
): GlobalState => {
  const { previouslyReceived, selectedPO, closedCancelled } = state;

  return {
    selectedPO: selectedPOToReceiveReducer(selectedPO, action),
    previouslyReceived: previouslyReceivedReducer(previouslyReceived, action),
    closedCancelled: closedCancelledReducer(closedCancelled, action),
  };
};
