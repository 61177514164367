import React from 'react';

import { ClosedCancelledPOList } from './ClosedCancelledPOList';
import { RACAccordion, makeStyles, Typography } from '@rentacenter/racstrap';
import { SectionProps } from './ReceivingPO';
import { POSection } from '../../constants/constants';
import Info from '../../app/assets/img/info.png';
import { RACTooltip } from '@rentacenter/racstrap';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: any) => ({
  ClosedCancelledPORoot: {
    margin: `${theme.typography.pxToRem(-35)} 0 7.5rem 0 !important`,
  },
  h4: {
    marginTop: '.5rem',
  },
  accordionSummaryContent: {
    margin: '0 !important',
  },
  infoIconStyle: {
    position: 'relative',
    left: '220px',
    top: '5px',
    zIndex: 999,
  },
}));

export const ClosedCancelledPOSection = ({
  selected,
  onChange,
}: SectionProps) => {
  const classes = useStyles();
  const expanded = selected === POSection.ClosedCancelledPO;
  const [headerPresent, setHeaderPresent] = useState(false);

  useEffect(() => {
    const { isRenderedByContainer } = window;
    setHeaderPresent(
      isRenderedByContainer != null && isRenderedByContainer != undefined
        ? true
        : false
    );
  }, []);
  return (
    <>
      <RACTooltip
        PopperProps={
          headerPresent
            ? {
                style: {
                  marginTop: -100,
                  paddingBottom: 75,
                  paddingLeft: 5,
                },
              }
            : {}
        }
        title="Closed POs mean the vendor has already been paid and are not reversible.  Cancelled POs mean the PO has been cancelled with the vendor."
      >
        <img className={classes.infoIconStyle} src={Info} alt="info-icon" />
      </RACTooltip>

      <RACAccordion
        details={<ClosedCancelledPOList expanded={expanded} />}
        summary={
          <Typography variant="h4" className={classes.h4}>
            Closed / Cancelled PO
          </Typography>
        }
        accordionProps={{
          classes: {
            root: classes.ClosedCancelledPORoot,
          },
          expanded: expanded,
          onChange: onChange(expanded ? '' : POSection.ClosedCancelledPO),
        }}
        summaryProps={{
          classes: {
            content: classes.accordionSummaryContent,
          },
        }}
      />
    </>
  );
};
