import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Icon,
  makeStyles,
  RACCheckBox,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTextField,
} from '@rentacenter/racstrap';
import { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { getCancelTokenSource } from '../../api/client';
import { getPurchaseOrders } from '../../api/purchaseOrders';
import {
  GetPurchaseOrderRequest,
  GetPurchaseOrderResponse,
  PurchaseOrder,
  UpdatedPOInformation,
  PurchaseOrderDetail,
} from '../../types/types';
import {
  formatDateString,
  formatMoney,
  getPONumber,
  getSelectedStore,
} from '../../utils/utils';
import {
  handlePartialRefusalSubmitted,
  ReceivedDetailRow,
  trimTextWithTooltip,
  addSerialNumberabdBol,
  POListProps,
} from './RegularPOList';
import {
  INITIAL_OFFSET,
  POSection,
  ManualPOStatusTypes,
  RECEIVING_PO_API_LIMIT,
} from '../../constants/constants';
import { RACInfiniteScroll } from '../../common/RACInfiniteScroll/RACInfiniteScroll';
import { ReceiveNowActions } from './ReceiveNowActions';
import {
  useSearchActions,
  useSearchDetails,
} from '../../context/Search/SearchProvider';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { useGlobalContext } from '../../context/global/GlobalContext';
import {
  setSelectedPOToReceive,
  setUpdatedPOData,
} from '../../context/global/reducer/selectedPOToReceiveActions';
import { useUserStateContext } from '../../context/user/user-contexts';
export const manualPOListTestId = 'manualPOListTestId';

const useClasses = makeStyles((theme: any) => ({
  purchaseOrderListWrapper: {
    width: '100%',
    height: theme.typography.pxToRem(290),
  },
  purchaseOrderList: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRadius: '1rem',
    overflowX: 'auto',
    height: theme.typography.pxToRem(290),
  },
  paddingTopBottom8: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  contentHeight: {
    height: theme.typography.pxToRem(233),
  },
  mainTableCell: {
    fontWeight: 'bolder',
    fontSize: '1rem',
  },
  childTableCellWidth80: {
    width: theme.typography.pxToRem(33),
    minWidth: theme.typography.pxToRem(33),
  },
  minWidth150: {
    width: '150px',
    minWidth: '150px',
  },
  childTableCellWidth140: {
    width: theme.typography.pxToRem(140),
  },
  childTableCellWidth50: {
    width: theme.typography.pxToRem(50),
  },
  childTableCellWidth130: {
    width: theme.typography.pxToRem(130),
  },
  childTableCellWidth73: {
    width: theme.typography.pxToRem(44),
    minWidth: theme.typography.pxToRem(44),
  },
  minWidth100: {
    minWidth: theme.typography.pxToRem(90),
  },
  childTableCellWidth100: {
    width: theme.typography.pxToRem(100),
  },
  childTableCellWidth150: {
    width: theme.typography.pxToRem(150),
  },
  childTableCellWidth250: {
    width: theme.typography.pxToRem(250),
  },
  childTableCellWidth: {
    width: theme.typography.pxToRem(180),
  },
  mainCheckbox: {
    width: `${theme.typography.pxToRem(40)} !important`,
  },
  disabled: {
    opacity: '.5',
  },
  mainRowWrapper: {
    display: 'flex',
  },
  checkbox: {
    padding: '0',
    width: theme.typography.pxToRem(120),
    height: theme.typography.pxToRem(50),
    '& svg': {
      width: theme.typography.pxToRem(26),
      height: theme.typography.pxToRem(26),
    },
  },
  receivedDetailContainer: {
    display: 'inline-flex',
    marginLeft: '10%',
    fontSize: theme.typography.pxToRem(14),
    width: '100%',
  },
  receivedDetailLabel: {
    fontWeight: 'bolder',
  },
  receivedDetailValue: {
    fontSize: theme.typography.pxToRem(14),
  },
  receivedDetailItem: {
    width: theme.typography.pxToRem(200),
    marginRight: '5%',
  },
  receivedDetailComment: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(300),
    marginRight: '5%',
  },
  childTableCellWidth196: {
    width: theme.typography.pxToRem(196),
  },
  mainCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mainCellDateColumn: {
    display: 'flex',
    flexGrow: 4,
    justifyContent: 'end',
  },
  marginRight5: {
    marginRight: theme.typography.pxToRem(5),
  },
  row: {
    display: 'flex',
    marginLeft: '6%',
    justifyContent: 'space-between',
  },
  tableHeader: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    backgroundColor: 'white',
  },
  field: {
    width: '12rem',
    '& input': {
      height: theme.typography.pxToRem(25),
    },
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(20),
    height: '100%',
  },
  fieldFlex: {
    display: 'flex',
    justifyContent: 'end',
    width: theme.typography.pxToRem(10),
  },
}));

export const ManualPOList = ({ expanded }: POListProps) => {
  const initialCallToken = useRef<CancelTokenSource>();
  const { user } = useUserStateContext();
  const [hasApiError, setApiHasError] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [selectedPO, setSelectedPO] = useState('');
  const [selectedReceivedDetailPO, setSelectedReceivedDetailPO] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const classes = useClasses();
  const isPurchaseOrdersEmpty = !purchaseOrders || purchaseOrders?.length === 0;
  const [partialReceivedPOs, setPartialReceivedPOs] = useState<
    UpdatedPOInformation[]
  >([]);
  const [totalCost, setTotalCost] = useState('');
  const { setSearchedPOInSection, setManualPOLoading, setSearchItemFound } =
    useSearchActions();
  const { filter, manualPOReceive, reloadData, queryParamsProp } =
    useSearchDetails();
  const { poNumber, toDate, fromDate } = filter || {};
  const { dispatch } = useGlobalContext();

  const clearPOFields = () => {
    setTotalCost('');
    dispatch(setSelectedPOToReceive({} as PurchaseOrder));
  };

  const onPOSelection = (po: PurchaseOrder) => {
    const poNumber = String(po.purchaseOrderNumber);
    const clearPo = selectedPO === poNumber;
    clearPo ? setSelectedPO('') : setSelectedPO(poNumber);
    setPartialReceivedPOs([]);
    setSelectedReceivedDetailPO([]);
    if (clearPo) {
      clearPOFields();
    } else {
      setTotalCost(po.totalCost);
      dispatch(setSelectedPOToReceive(po));
    }
    dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
  };

  const renderTableHead = () => (
    <>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth80),
        }}
      />
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.minWidth150),
        }}
      >
        PO #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(
            classes.tableHeader,
            classes.childTableCellWidth100,
            classes.minWidth100
          ),
        }}
      >
        Created
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Item #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Model #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth250),
        }}
      >
        Description
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Brand
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth100),
        }}
      >
        Cost
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Receiving Now
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Serial #
      </RACTableCell>
    </>
  );

  const renderTableContent = () => {
    if (isPurchaseOrdersEmpty) return <></>;

    return renderTableContentFilled();
  };

  const generateReceivedDetailsMPO = (
    poReceivedDetails: PurchaseOrderDetail[]
  ) => {
    const poReceived: any[] = [];

    poReceivedDetails.forEach((item) => {
      if (item?.quantityOrdered !== item?.receivedCount) {
        const totalPONotReceived =
          item?.quantityOrdered - (item?.receivedCount || 0);
        for (let index = 0; index < totalPONotReceived; index++) {
          poReceived.push(item);
        }
      }

      if (item.purchaseOrderReceivedDetails?.length) {
        item.purchaseOrderReceivedDetails.forEach((detail) => {
          poReceived.push({
            ...item,
            ...detail,
          });
        });
      }
    });
    return poReceived;
  };

  const renderTableContentFilled = () => (
    <>
      {purchaseOrders?.map((po, index) => {
        const poNumber = String(po.purchaseOrderNumber);
        const showPODetail = poNumber === selectedPO && po.purchaseOrderDetails;
        return (
          <RACTableRow key={index} backgroundColor="white">
            <>
              <RACTableCell
                classes={{
                  root: clsx(
                    classes.paddingTopBottom8,
                    classes.childTableCellWidth100
                  ),
                }}
                colSpan={10}
              >
                <div className={classes.mainCellContainer}>
                  <RACCheckBox
                    id={String(po.purchaseOrderNumber)}
                    color="primary"
                    onChange={() => onPOSelection(po)}
                    checked={selectedPO === poNumber}
                    classes={{
                      checkbox: clsx(classes.checkbox, classes.mainCheckbox),
                    }}
                  />
                  <div className={classes.mainRowWrapper}>
                    <div
                      className={clsx(
                        classes.mainTableCell,
                        classes.minWidth150
                      )}
                    >
                      {getPONumber(po)}
                    </div>
                    <div className={clsx(classes.minWidth100)}>
                      {`${formatDateString(po.createdDate)}`}
                    </div>
                  </div>
                </div>
                {showPODetail && (
                  <RACTable
                    renderTableHead={() => <></>}
                    renderTableContent={() =>
                      renderChildTableContent(
                        po.purchaseOrderId,
                        generateReceivedDetailsMPO(po.purchaseOrderDetails)
                      )
                    }
                  />
                )}
              </RACTableCell>
            </>
          </RACTableRow>
        );
      })}
    </>
  );

  const renderChildTableContent = (
    purchaseOrderId: string,
    poReceivedDetails: PurchaseOrderDetail[]
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => (
    <>
      {poReceivedDetails
        ?.flatMap((i) => [i, i])
        .map((poReceived: any, index) => {
          const receivedId = `${purchaseOrderId}-${
            poReceived?.purchaseOrderDetailId
          }-${poReceived?.purchaseOrderDetailReceivedId || index}`;
          const previousReceivedIdRow = `${purchaseOrderId}-${
            poReceived?.purchaseOrderDetailId
          }-${poReceived?.purchaseOrderDetailReceivedId || index - 1}`;
          const isPartial = !!poReceived?.partialReasonId;
          const isItemReceived = !!poReceived?.purchaseOrderDetailReceivedId;
          const isPreviouslyReceived =
            isItemReceived && !poReceived?.partialReasonId;
          const isSelected = selectedReceivedDetailPO.includes(receivedId);

          if (index % 2 !== 0) {
            return (
              <ReceivedDetailRow
                key={index}
                index={index - 1}
                partialReceivedPO={partialReceivedPOs}
                poReceivedDetail={poReceived}
                isDisabled={
                  !selectedReceivedDetailPO.includes(previousReceivedIdRow)
                }
                isPartial={isPartial}
                isPreviouslyReceived={isPreviouslyReceived}
              />
            );
          }

          return (
            <RACTableRow key={index} backgroundColor="white">
              <>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.checkbox,
                      classes.childTableCellWidth100
                    ),
                  }}
                >
                  <RACCheckBox
                    id={receivedId}
                    color="primary"
                    onChange={() =>
                      isSelected
                        ? setSelectedReceivedDetailPO(
                            selectedReceivedDetailPO.filter(
                              (item) => item !== receivedId
                            )
                          )
                        : setSelectedReceivedDetailPO([
                            ...selectedReceivedDetailPO,
                            receivedId,
                          ])
                    }
                    checked={isSelected || (isPreviouslyReceived && !isPartial)}
                    classes={{ checkbox: classes.checkbox }}
                    disabled={isPreviouslyReceived && !isPartial}
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth196,
                      !isSelected && classes.disabled
                    ),
                  }}
                ></RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {poReceived?.inventoryNumber || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {trimTextWithTooltip(poReceived?.modelNumber) || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth250,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {trimTextWithTooltip(poReceived?.itemDescription) || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {poReceived?.brand || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth100,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {formatMoney(poReceived?.itemCost)}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <ReceiveNowActions
                    isDisabled={!isSelected}
                    isPartialReceivingNowSelected={isPartial}
                    onPartialRefusalSubmitted={(reason, comment, action) => {
                      const updatedPO = handlePartialRefusalSubmitted(
                        purchaseOrderId,
                        reason,
                        comment,
                        poReceived,
                        index,
                        action,
                        partialReceivedPOs,
                        'ER'
                      );
                      setPartialReceivedPOs(updatedPO);
                      dispatch(setUpdatedPOData(updatedPO));
                    }}
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <RACTextField
                    name={`serial-${receivedId}`}
                    value={poReceived?.manufacturerSerialNumber || ''}
                    type="text"
                    inputProps={{ maxLength: 20 }}
                    onBlur={() =>
                      dispatch(setUpdatedPOData(partialReceivedPOs))
                    }
                    onChange={(value) =>
                      setPartialReceivedPOs(
                        addSerialNumberabdBol(
                          purchaseOrderId,
                          value,
                          null,
                          poReceived,
                          index,
                          partialReceivedPOs,
                          'ER'
                        )
                      )
                    }
                    disabled={!isSelected}
                  />
                </RACTableCell>
              </>
            </RACTableRow>
          );
        })}
    </>
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchPurchaseOrders = (isFilterChanged = false) => {
    if (queryParamsProp?.origin == 'TB') return;
    if (initialCallToken?.current) {
      initialCallToken.current?.cancel();
    }
    initialCallToken.current = getCancelTokenSource();

    setApiHasError(false);
    setLoading(true);
    const storeNumber = getSelectedStore();

    const request: GetPurchaseOrderRequest = {
      purchaseOrderType: ['ER'],
      poStatusType: ManualPOStatusTypes,
    };

    if (!manualPOReceive) {
      request.storeNumber = [storeNumber];
    } else {
      request.coworkerId = user?.employeeId;
    }

    if (fromDate && toDate) {
      request.poDateRange = {
        endDate: toDate,
        startDate: fromDate,
      };
    }

    if (poNumber) {
      request.purchaseOrderNumber = poNumber;
      request.coworkerId = user?.employeeId;
    }

    getPurchaseOrders(
      request,
      offset,
      RECEIVING_PO_API_LIMIT,
      initialCallToken.current?.token
    )
      .then((response: GetPurchaseOrderResponse) => {
        const hasPO = !!response.purchaseOrders.length;
        if (hasPO) {
          if (
            isFilterChanged ||
            response?.purchaseOrders.length <= RECEIVING_PO_API_LIMIT
          ) {
            setPurchaseOrders(response.purchaseOrders);
          } else {
            setPurchaseOrders([...purchaseOrders, ...response.purchaseOrders]);
          }
          if (poNumber) {
            setSearchItemFound(true);
            setSearchedPOInSection(POSection.ManualPO);
          }
          if (manualPOReceive) {
            onPOSelection(response.purchaseOrders[0]);
          }
        }
        setHasMore(
          hasPO && response?.purchaseOrders.length === RECEIVING_PO_API_LIMIT
        );
        setLoading(false);
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          setApiHasError(true);
          setPurchaseOrders([]);
          setLoading(false);
        }
      });
  };

  const reload = () => {
    setOffset(INITIAL_OFFSET);
    setTotalCost('');
    setPartialReceivedPOs([]);
    setSelectedReceivedDetailPO([]);
    setSelectedPO('');
    dispatch(setSelectedPOToReceive({} as PurchaseOrder));
    dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    setPurchaseOrders([]);
    fetchPurchaseOrders(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchPurchaseOrders, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchPurchaseOrders, [offset, user]);

  useEffect(() => {
    if (reloadData) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reload, [poNumber, fromDate, toDate]);

  useEffect(() => {
    setManualPOLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!expanded) {
      setTotalCost('');
      setPartialReceivedPOs([]);
      setSelectedReceivedDetailPO([]);
      setSelectedPO('');
      dispatch(setSelectedPOToReceive({} as PurchaseOrder));
      dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleRefresh = () => {
    setOffset(INITIAL_OFFSET);
    setTotalCost('');
    setPartialReceivedPOs([]);
    setSelectedReceivedDetailPO([]);
    setSelectedPO('');
    dispatch(setSelectedPOToReceive({} as PurchaseOrder));
    dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    setPurchaseOrders([]);
    const isFilterSelected = !!poNumber || !!fromDate || !!toDate;
    fetchPurchaseOrders(isFilterSelected);
  };

  return (
    <div className={classes.purchaseOrderListWrapper}>
      <div
        className={classes.purchaseOrderList}
        data-testid={manualPOListTestId}
      >
        <div>
          <div className={classes.row}>
            <div className={classes.field}>
              <FieldWithTooltip
                id="namualPOTotalCost"
                currency
                title="Total Cost"
              >
                <AutoNumeric
                  name="regularPOTotalCost"
                  value={totalCost}
                  disabled
                />
              </FieldWithTooltip>
            </div>
            <div className={clsx(classes.field, classes.fieldFlex)}>
              <Icon classes={{ root: classes.icon }} onClick={handleRefresh}>
                <FontAwesomeIcon icon={faSync} />
              </Icon>
            </div>
          </div>
          <div>
            <RACInfiniteScroll
              offset={offset}
              setOffset={setOffset}
              data={purchaseOrders}
              loading={loading}
              hasMore={hasMore}
              hasApiError={hasApiError}
              classes={{
                loader: classes.contentHeight,
                apiError: classes.contentHeight,
                noItems: classes.contentHeight,
              }}
              successContent={
                <RACTable
                  renderTableHead={renderTableHead}
                  renderTableContent={renderTableContent}
                  stickyHeader
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
