import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Icon,
  makeStyles,
  RACCheckBox,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTextField,
} from '@rentacenter/racstrap';
import { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { getCancelTokenSource } from '../../api/client';
import { getPurchaseOrders } from '../../api/purchaseOrders';
import {
  GetPurchaseOrderRequest,
  GetPurchaseOrderResponse,
  PurchaseOrder,
  UpdatedPOInformation,
  PurchaseOrderDetail,
} from '../../types/types';
import {
  formatDateString,
  formatMoney,
  getPONumber,
  getSelectedStore,
} from '../../utils/utils';
import {
  handlePartialRefusalSubmitted,
  ReceivedDetailRow,
  trimTextWithTooltip,
  POListProps,
} from './RegularPOList';
import {
  INITIAL_OFFSET,
  POSection,
  RcvdRegAndMPOTypes,
  RECEIVING_PO_API_LIMIT,
} from '../../constants/constants';
import { ReceiveNowActions } from './ReceiveNowActions';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { useGlobalContext } from '../../context/global/GlobalContext';
import {
  setClosedCancelled,
  setSelectedClosedCancelledId,
} from '../../context/global/reducer/closedCancelledActions';
import {
  useSearchActions,
  useSearchDetails,
} from '../../context/Search/SearchProvider';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import {
  setSelectedPOToReceive,
  setUpdatedPOData,
} from '../../context/global/reducer/selectedPOToReceiveActions';
import { setSelectedPORev } from '../../context/global/reducer/selectedPORevActions';

export const ClosedCancelledPOListTestId = 'ClosedCancelledPOListTestId';

const useClasses = makeStyles((theme: any) => ({
  purchaseOrderListWrapper: {
    width: '100%',
    height: theme.typography.pxToRem(290),
  },
  purchaseOrderList: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRadius: '1rem',
    overflowX: 'auto',
    height: theme.typography.pxToRem(290),
  },
  paddingTopBottom8: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  contentHeight: {
    height: theme.typography.pxToRem(233),
  },
  mainTableCell: {
    fontWeight: 'bolder',
    fontSize: '1rem',
  },
  childTableCellWidth50: {
    width: theme.typography.pxToRem(50),
  },
  childTableCellWidth130: {
    width: theme.typography.pxToRem(130),
  },
  childTableCellWidth33: {
    width: '33px',
    minWidth: '33px',
  },
  childTableCellWidth100: {
    width: theme.typography.pxToRem(100),
  },
  childTableCellWidth150: {
    width: theme.typography.pxToRem(150),
  },
  childTableCellWidth250: {
    width: theme.typography.pxToRem(250),
  },
  childTableCellWidth: {
    width: theme.typography.pxToRem(180),
  },
  mainCheckbox: {
    width: `${theme.typography.pxToRem(40)} !important`,
  },
  disabled: {
    opacity: '.5',
  },
  checkboxChecked: {
    display: 'flex',
  },
  checkbox: {
    padding: '0',
    width: theme.typography.pxToRem(120),
    height: theme.typography.pxToRem(50),
    '& svg': {
      width: theme.typography.pxToRem(26),
      height: theme.typography.pxToRem(26),
    },
  },
  receivedDetailContainer: {
    display: 'inline-flex',
    marginLeft: '10%',
    fontSize: theme.typography.pxToRem(14),
    width: '100%',
  },
  receivedDetailLabel: {
    fontWeight: 'bolder',
  },
  receivedDetailValue: {
    fontSize: theme.typography.pxToRem(14),
  },
  receivedDetailItem: {
    width: theme.typography.pxToRem(200),
    marginRight: '5%',
  },
  minWidth150: {
    width: '150px',
    minWidth: '150px',
  },
  receivedDetailComment: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(300),
    marginRight: '5%',
  },
  field: {
    width: '12rem',
    '& input': {
      height: theme.typography.pxToRem(25),
    },
  },
  tableHeader: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    backgroundColor: 'white',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '6%',
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(20),
    height: '100%',
  },
  fieldFlex: {
    display: 'flex',
    justifyContent: 'end',
    width: theme.typography.pxToRem(10),
  },
  mainTableCellWrapper: {
    display: 'flex',
  },
  mainTableCellWidth116: {
    width: theme.typography.pxToRem(116),
  },
  fieldWidth70percent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    minWidth: theme.typography.pxToRem(600),
  },
}));

const generateDetailLineItem = (poReceivedDetails: PurchaseOrderDetail[]) => {
  const flatPOReceivedDetails: any[] = [];

  poReceivedDetails.forEach((item) => {
    flatPOReceivedDetails.push(item);
  });
  return flatPOReceivedDetails;
};

export const ClosedCancelledPOList = ({ expanded }: POListProps) => {
  const initialCallToken = useRef<CancelTokenSource>();
  const [hasApiError, setApiHasError] = useState(false);

  const {
    dispatch,
    closedCancelled: { data: purchaseOrders, selectedItemId: selectedPO },
  } = useGlobalContext();

  const [selectedReceivedDetailPO, setSelectedReceivedDetailPO] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(false);
  const classes = useClasses();
  const isPurchaseOrdersEmpty = !purchaseOrders || purchaseOrders?.length === 0;
  const [partialReceivedPOs, setPartialReceivedPOs] = useState<
    UpdatedPOInformation[]
  >([]);
  const [revItems, setRevItems] = useState<UpdatedPOInformation[]>([]);
  const {
    setSearchedPOInSection,
    setClosedCancelledPOLoading,
    setSearchItemFound,
  } = useSearchActions();
  const { filter, manualPOReceive, reloadData } = useSearchDetails();
  const { poNumber, toDate, fromDate } = filter || {};
  const [vendorName, setVendorName] = useState('');
  const [totalCost, setTotalCost] = useState('');
  const [eta, setEta] = useState('');

  const clearPOFields = () => {
    setVendorName('');
    setTotalCost('');
    setEta('');

    dispatch(setSelectedPORev({} as PurchaseOrder));
  };

  const onPOSelection = (po: PurchaseOrder) => {
    const poNumber = String(po.purchaseOrderNumber);
    const clearPo = selectedPO === poNumber;

    clearPo
      ? dispatch(setSelectedClosedCancelledId(''))
      : dispatch(setSelectedClosedCancelledId(poNumber));
    setPartialReceivedPOs([]);
    setRevItems([]);
    setSelectedReceivedDetailPO([]);
    if (clearPo) {
      clearPOFields();
    } else {
      po.vendorName && setVendorName(po.vendorName);
      setTotalCost(po.totalCost);
      po.estimatedDeliveryDate &&
        setEta(formatDateString(po.estimatedDeliveryDate));
    }
  };

  const renderTableHead = () => (
    <>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth33),
        }}
      />
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.minWidth150),
        }}
      >
        PO #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Item #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Model #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth250),
        }}
      >
        Description
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Brand
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth100),
        }}
      >
        Cost
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Receiving Now
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Serial #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Bol
      </RACTableCell>
    </>
  );

  const renderTableContent = () => {
    if (isPurchaseOrdersEmpty) return <></>;

    return renderTableContentFilled();
  };

  const renderTableContentFilled = () => (
    <>
      {purchaseOrders?.map((po, index) => {
        const poNumber = String(po.purchaseOrderNumber);
        const showPODetail = poNumber === selectedPO && po.purchaseOrderDetails;
        return (
          <RACTableRow key={index} backgroundColor="white">
            <>
              <RACTableCell
                classes={{
                  root: clsx(
                    classes.paddingTopBottom8,
                    classes.childTableCellWidth100
                  ),
                }}
                colSpan={10}
              >
                <div>
                  <RACCheckBox
                    id={String(po.purchaseOrderNumber)}
                    color="primary"
                    onChange={() => onPOSelection(po)}
                    checked={selectedPO === poNumber}
                    classes={{
                      checkbox: clsx(classes.checkbox, classes.mainCheckbox),
                    }}
                  />
                  <span
                    className={clsx(classes.mainTableCell, classes.minWidth150)}
                  >
                    {getPONumber(po)}
                  </span>
                </div>
                {showPODetail && (
                  <RACTable
                    renderTableHead={() => <></>}
                    renderTableContent={() =>
                      renderChildTableContent(
                        po.purchaseOrderId,
                        po.closeDate,
                        po.cancelDate,
                        generateDetailLineItem(po.purchaseOrderDetails)
                      )
                    }
                  />
                )}
              </RACTableCell>
            </>
          </RACTableRow>
        );
      })}
    </>
  );

  const handleItemSelect = (
    purchaseOrderId: string,
    poReceived: any,
    isSelected: boolean,
    receivedId: any
  ) => {
    if (isSelected) {
      setSelectedReceivedDetailPO(
        selectedReceivedDetailPO.filter((item) => item !== receivedId)
      );
      setRevItems(
        revItems.filter(
          (item) =>
            item.purchaseOrderDetailReceivedId !==
            poReceived.purchaseOrderDetailReceivedId
        )
      );
    } else {
      setSelectedReceivedDetailPO([...selectedReceivedDetailPO, receivedId]);
      const newItem: UpdatedPOInformation = {
        purchaseOrderId,
        purchaseOrderDetailId: poReceived?.purchaseOrderDetailId,
        purchaseOrderDetailReceivedId:
          poReceived?.purchaseOrderDetailReceivedId,
      };
      setRevItems([...revItems, newItem]);
    }
  };

  const renderChildTableContent = (
    purchaseOrderId: string,
    closeDate: string,
    cancelDate: string,
    poReceivedDetails: PurchaseOrderDetail[]
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => (
    <>
      {poReceivedDetails
        ?.flatMap((i) => [i, i])
        .map((poReceived: any, index) => {
          const poDetailReceivedId =
            poReceived?.purchaseOrderReceivedDetails[0]
              ?.purchaseOrderDetailReceivedId;

          const receivedId = `${purchaseOrderId}-${
            poReceived?.purchaseOrderDetailId
          }-${poDetailReceivedId || index}`;

          const previousReceivedIdRow = `${purchaseOrderId}-${
            poReceived?.purchaseOrderDetailId
          }-${poDetailReceivedId || index - 1}`;

          const poReceiveDetail = poReceived?.purchaseOrderReceivedDetails[0];
          // const isOldItemReceived = !!poReceived?.purchaseOrderDetailReceivedId;
          const bolToDisplay = poReceived?.purchaseOrderReceivedDetails[0]?.bol;

          const serialNumberToDisplay =
            poReceived?.purchaseOrderReceivedDetails[0]
              ?.manufacturerSerialNumber;

          const isItemReceived = !!poDetailReceivedId;

          const isPreviouslyReceived =
            isItemReceived && !poReceived?.partialReasonId;
          const closeCancelDate = closeDate
            ? closeDate
            : cancelDate
            ? cancelDate
            : ' ';
          const isSelected = selectedReceivedDetailPO.includes(receivedId);
          const isPartial = !!poReceived?.partialReasonId;
          const isRevesible = poReceived?.reversible || false;
          if (index % 2 !== 0) {
            return (
              <ReceivedDetailRow
                key={index}
                index={index - 1}
                partialReceivedPO={partialReceivedPOs}
                poReceivedDetail={poReceiveDetail}
                isDisabled={
                  !selectedReceivedDetailPO.includes(previousReceivedIdRow)
                }
                isPartial={isPartial}
                isPreviouslyReceived={isPreviouslyReceived}
                closeCancelDate={closeCancelDate}
                dateReceivedColumnNeeded
              />
            );
          }

          return (
            <RACTableRow key={index} backgroundColor="white">
              <>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.checkbox,
                      classes.childTableCellWidth100
                    ),
                  }}
                >
                  <RACCheckBox
                    id={receivedId}
                    color="primary"
                    onChange={() =>
                      handleItemSelect(
                        purchaseOrderId,
                        poReceived,
                        isSelected,
                        receivedId
                      )
                    }
                    classes={{ checkbox: classes.checkbox }}
                    checked={isSelected}
                    disabled={!isRevesible}
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth50,
                      !isSelected && classes.disabled
                    ),
                  }}
                ></RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {poReceived?.inventoryNumber || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {trimTextWithTooltip(poReceived?.modelNumber) || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth250,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {trimTextWithTooltip(poReceived?.itemDescription) || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {poReceived?.brand || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth100,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {formatMoney(poReceived?.itemCost)}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <ReceiveNowActions
                    isDisabled
                    isPartialReceivingNowSelected={isPartial}
                    onPartialRefusalSubmitted={(reason, comment, action) =>
                      setPartialReceivedPOs(
                        handlePartialRefusalSubmitted(
                          purchaseOrderId,
                          reason,
                          comment,
                          poReceived,
                          index,
                          action,
                          partialReceivedPOs
                        )
                      )
                    }
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <RACTextField
                    name={`serial-${receivedId}`}
                    value={serialNumberToDisplay || ''}
                    type="text"
                    disabled
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <RACTextField
                    name={`bol-${receivedId}`}
                    value={bolToDisplay || ''}
                    type="text"
                    disabled
                  />
                </RACTableCell>
              </>
            </RACTableRow>
          );
        })}
    </>
  );

  const fetchPurchaseOrders = () => {
    if (manualPOReceive) return;
    if (initialCallToken?.current) {
      initialCallToken.current?.cancel();
    }
    initialCallToken.current = getCancelTokenSource();

    setApiHasError(false);
    setLoading(true);
    const storeNumber = getSelectedStore();

    const request: GetPurchaseOrderRequest = {
      storeNumber: [storeNumber],
      purchaseOrderType: RcvdRegAndMPOTypes,
      poStatusType: ['OC'],
    };

    if (fromDate && toDate) {
      request.poDateRange = {
        endDate: toDate,
        startDate: fromDate,
      };
    }

    if (poNumber) {
      request.purchaseOrderNumber = poNumber;
    }

    getPurchaseOrders(
      request,
      INITIAL_OFFSET,
      RECEIVING_PO_API_LIMIT,
      initialCallToken.current?.token
    )
      .then((response: GetPurchaseOrderResponse) => {
        if (response?.purchaseOrders?.length) {
          dispatch(setClosedCancelled(response.purchaseOrders));
          if (poNumber) {
            setSearchItemFound(true);
            setSearchedPOInSection(POSection.ClosedCancelledPO);
          }
        } else {
          dispatch(setClosedCancelled([]));
        }

        setLoading(false);
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          setApiHasError(true);
          dispatch(setClosedCancelled([]));
          setLoading(false);
        }
      });
  };

  const reload = () => {
    setVendorName('');
    setTotalCost('');
    setEta('');
    setPartialReceivedPOs([]);
    setSelectedReceivedDetailPO([]);
    dispatch(setSelectedClosedCancelledId(''));
    dispatch(setSelectedPOToReceive({} as PurchaseOrder));
    dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    dispatch(setClosedCancelled([]));
    fetchPurchaseOrders();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchPurchaseOrders, []);

  useEffect(() => {
    if (reloadData) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reload, [poNumber, fromDate, toDate]);

  useEffect(() => {
    setClosedCancelledPOLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!expanded) {
      setVendorName('');
      setTotalCost('');
      setEta('');
      setPartialReceivedPOs([]);
      setSelectedReceivedDetailPO([]);
      dispatch(setSelectedClosedCancelledId(''));
    } else {
      dispatch(setSelectedPOToReceive({} as PurchaseOrder));
      dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <div className={classes.purchaseOrderListWrapper}>
      <div
        className={classes.purchaseOrderList}
        data-testid={ClosedCancelledPOListTestId}
      >
        <div>
          <div className={classes.row}>
            <div className={classes.fieldWidth70percent}>
              <div className={classes.field}>
                <RACTextField
                  label="Vendor Name"
                  type="text"
                  name="vendorName"
                  value={vendorName}
                  disabled
                />
              </div>
              <div className={classes.field}>
                <FieldWithTooltip
                  id="regularPOTotalCost"
                  currency
                  title="Total Cost"
                >
                  <AutoNumeric
                    name="regularPOTotalCost"
                    value={totalCost}
                    disabled
                  />
                </FieldWithTooltip>
              </div>
              <div className={classes.field}>
                <RACTextField
                  label="ETA"
                  type="text"
                  name="eta"
                  value={eta}
                  disabled
                />
              </div>
            </div>
            <div className={clsx(classes.field, classes.fieldFlex)}>
              <Icon classes={{ root: classes.icon }} onClick={reload}>
                <FontAwesomeIcon icon={faSync} />
              </Icon>
            </div>
          </div>
          <div>
            <ApiStateWrapper
              loading={loading && purchaseOrders?.length === 0}
              hasApiError={hasApiError && purchaseOrders?.length === 0}
              response={purchaseOrders}
              successContent={
                <RACTable
                  renderTableHead={renderTableHead}
                  renderTableContent={renderTableContent}
                  stickyHeader
                />
              }
              classes={{
                loader: classes.contentHeight,
                apiError: classes.contentHeight,
                noItems: classes.contentHeight,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
